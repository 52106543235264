class GetCurrentAddressStr {
  inside = (point: number[], vs: number[][]) => {
    // ray-casting algorithm based on
    // https://wrf.ecse.rpi.edu/Research/Short_Notes/pnpoly.html/pnpoly.html

    var x = point[0],
      y = point[1];

    var inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      var xi = vs[i][0],
        yi = vs[i][1];
      var xj = vs[j][0],
        yj = vs[j][1];

      var intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  };

  getCurrentAddressStr = (point: number[]): string => {
    let arr_addr_en = [
      ['WATSON', 'NORTHSIDE'],
      ['WATSON', 'ARASAKA WATERFRONT'],
      ['WATSON', 'LITTLE CHINA'],
      ['WATSON', 'KABUKI'],
      ['WESTBROOK', 'NORTH OAK'],
      ['WESTBROOK', 'CHARTER HILL'],
      ['WESTBROOK', 'JAPANTOWN'],
      ['CITY CENTER', 'DOWNTOWN'],
      ['CITY CENTER', 'CORPO PLAZA'],
      ['HEYWOOD', 'VISTA DEL REY'],
      ['HEYWOOD', 'THE GLEN'],
      ['HEYWOOD', 'WELLSPRINGS'],
      ['SANTO DOMINGO', 'RANCHO CORONADO'],
      ['SANTO DOMINGO', 'ARROYO'],
      ['PACIFICA', 'COAST VIEW'],
      ['PACIFICA', 'WEST WIND ESTATE'],
    ];

    let arr_addr_kr = [
      ['왓슨', '노스사이드'],
      ['왓슨', '아라사카 워터프론트'],
      ['왓슨', '리틀 차이나'],
      ['왓슨', '가부키'],
      ['웨스트브룩', '노스 오크'],
      ['웨스트브룩', '차터 힐'],
      ['웨스트브룩', '재팬타운'],
      ['도심', '다운타운'],
      ['도심', '기업 플라자'],
      ['헤이우드', '비스타 델 레이'],
      ['헤이우드', '글렌'],
      ['헤이우드', '웰스프링스'],
      ['산토 도밍고', '란초 코로나도'],
      ['산토 도밍고', '아로요'],
      ['퍼시피카', '코스트뷰'],
      ['퍼시피카', '웨스트 윈드 에스테이트'],
    ];

    let arr_min_max_rect = [
      [
        [1107.032, 421.534],
        [1107.032, 1131.575],
        [1950.688, 1131.575],
        [1950.688, 421.534],
      ],
      [
        [842.996, 621.536],
        [842.996, 1185.201],
        [1383.469, 1185.201],
        [1383.469, 621.536],
      ],
      [
        [1156.571, 1064.219],
        [1156.571, 1426.583],
        [1611.661, 1426.583],
        [1611.661, 1064.219],
      ],
      [
        [1496.77, 929.545],
        [1496.77, 1380.639],
        [1707.476, 1380.639],
        [1707.476, 929.545],
      ],
      [
        [1865.807, 870.37],
        [1865.807, 1622.929],
        [2329.231, 1622.929],
        [2329.231, 870.37],
      ],
      [
        [1815.128, 1509.059],
        [1815.128, 1918.016],
        [2288.585, 1918.016],
        [2288.585, 1509.059],
      ],
      [
        [1585.784, 977.853],
        [1585.784, 1743.632],
        [1955.978, 1743.632],
        [1955.978, 977.853],
      ],
      [
        [927.764, 1420.781],
        [927.764, 1733.913],
        [1487.707, 1733.913],
        [1487.707, 1420.781],
      ],
      [
        [1322.222, 1425.298],
        [1322.222, 1852.389],
        [1691.683, 1852.389],
        [1691.683, 1425.298],
      ],
      [
        [1551.328, 1590.303],
        [1551.328, 2061.505],
        [1825.197, 2061.505],
        [1825.197, 1590.303],
      ],
      [
        [1262.77, 1731.751],
        [1262.77, 2199.371],
        [1611.399, 2199.371],
        [1611.399, 1731.751],
      ],
      [
        [1001.385, 1718.641],
        [1001.385, 2202.37],
        [1275.186, 2202.37],
        [1275.186, 1718.641],
      ],
      [
        [1746.39, 1884.958],
        [1746.39, 2518.78],
        [2359.661, 2518.78],
        [2359.661, 1884.958],
      ],
      [
        [1510.571, 1762.725],
        [1510.571, 2489.005],
        [2098.802, 2489.005],
        [2098.802, 1762.725],
      ],
      [
        [880.893, 2142.705],
        [880.893, 2531.854],
        [1653.451, 2531.854],
        [1653.451, 2142.705],
      ],
      [
        [731.155, 2292.229],
        [731.155, 2791.833],
        [1490.755, 2791.833],
        [1490.755, 2292.229],
      ],
    ];

    let arr_coords = [
      [
        [1107.032, 621.536],
        [1287.11, 610.537],
        [1373.348, 503.025],
        [1567.563, 421.534],
        [1726.813, 449.736],
        [1808.026, 492.6],
        [1950.688, 870.372],
        [1896.296, 908.703],
        [1865.807, 977.856],
        [1866.769, 1028.768],
        [1831.248, 1071.502],
        [1834.467, 1085.813],
        [1707.478, 1131.575],
        [1699.183, 1066.221],
        [1683.985, 1032.274],
        [1695.474, 976.088],
        [1642.838, 929.544],
        [1591.737, 958.265],
        [1545.736, 954.033],
        [1496.773, 989.586],
        [1510.817, 1040.56],
        [1535.425, 1067.534],
        [1383.473, 1064.22],
        [1242.387, 811.565],
        [1198.165, 809.242],
        [1171.8, 767.379],
        [1189.064, 723.163],
      ],
      [
        [1156.571, 1185.201],
        [842.996, 1084.301],
        [873.06, 772.588],
        [1107.032, 621.536],
        [1189.06, 723.162],
        [1171.796, 767.378],
        [1198.161, 809.241],
        [1242.383, 811.564],
        [1383.469, 1064.219],
      ],
      [
        [1156.571, 1185.201],
        [1383.47, 1064.219],
        [1535.422, 1067.534],
        [1523.63, 1380.639],
        [1611.661, 1378.647],
        [1585.783, 1425.3],
        [1227.427, 1426.583],
      ],
      [
        [1611.661, 1378.647],
        [1523.63, 1380.639],
        [1535.422, 1067.534],
        [1510.814, 1040.561],
        [1496.77, 989.587],
        [1545.734, 954.034],
        [1591.735, 958.266],
        [1642.836, 929.545],
        [1695.472, 976.089],
        [1683.983, 1032.275],
        [1699.181, 1066.222],
        [1707.476, 1131.576],
        [1633.954, 1196.838],
        [1635.535, 1289.797],
        [1610.836, 1315.605],
      ],
      [
        [2329.231, 1527.879],
        [2327.047, 1288.81],
        [2251.56, 1276.165],
        [2089.582, 1088.548],
        [2061.396, 1078.576],
        [1969.936, 922.714],
        [1950.688, 870.37],
        [1896.296, 908.701],
        [1865.807, 977.854],
        [1887.227, 991.124],
        [1936.324, 1164.165],
        [1878.479, 1268.821],
        [1926.513, 1269.98],
        [1955.977, 1414.466],
        [1934.548, 1415.962],
        [1936.426, 1509.058],
        [1982.766, 1577.055],
        [2038.977, 1608.888],
        [2145.004, 1622.929],
        [2180.622, 1581.735],
      ],
      [
        [2288.585, 1884.957],
        [2255.534, 1747.992],
        [2145.004, 1622.93],
        [2038.977, 1608.889],
        [1982.766, 1577.056],
        [1936.426, 1509.059],
        [1881.167, 1531.997],
        [1889.131, 1550.884],
        [1815.128, 1743.634],
        [1825.198, 1762.724],
        [1890.279, 1813.189],
        [1903.765, 1799.605],
        [2098.802, 1918.016],
      ],
      [
        [1585.784, 1425.299],
        [1611.662, 1378.646],
        [1610.839, 1315.601],
        [1635.538, 1289.793],
        [1633.957, 1196.834],
        [1707.479, 1131.572],
        [1834.468, 1085.81],
        [1831.249, 1071.499],
        [1866.77, 1028.765],
        [1865.808, 977.853],
        [1887.228, 991.123],
        [1936.325, 1164.164],
        [1878.48, 1268.82],
        [1926.514, 1269.979],
        [1955.978, 1414.465],
        [1934.549, 1415.961],
        [1936.427, 1509.057],
        [1881.168, 1531.995],
        [1889.132, 1550.882],
        [1849.407, 1674.037],
        [1815.129, 1743.632],
        [1706.325, 1649.288],
        [1691.685, 1590.3],
      ],
      [
        [1322.224, 1671.29],
        [1353.373, 1691.912],
        [1355.754, 1733.913],
        [1038.035, 1718.641],
        [1002.838, 1597.564],
        [927.764, 1517.406],
        [1025.73, 1420.781],
        [1116.515, 1508.285],
        [1227.429, 1426.582],
        [1487.707, 1426.899],
        [1481.919, 1553.825],
        [1415.64, 1550.874],
        [1416.969, 1617.309],
        [1387.945, 1661.939],
        [1344.131, 1640.003],
      ],
      [
        [1691.683, 1590.303],
        [1618.72, 1691.833],
        [1567.144, 1703.413],
        [1577.212, 1729.46],
        [1551.328, 1817.658],
        [1505.315, 1852.389],
        [1434.77, 1848.75],
        [1383.725, 1802.337],
        [1355.752, 1733.912],
        [1353.371, 1691.911],
        [1322.222, 1671.289],
        [1344.129, 1640.002],
        [1387.943, 1661.938],
        [1416.967, 1617.308],
        [1415.638, 1550.873],
        [1481.917, 1553.824],
        [1487.705, 1426.898],
        [1585.782, 1425.298],
      ],
      [
        [1611.399, 2061.505],
        [1825.197, 1762.725],
        [1815.127, 1743.635],
        [1706.323, 1649.291],
        [1691.683, 1590.303],
        [1618.72, 1691.833],
        [1567.144, 1703.413],
        [1577.212, 1729.46],
        [1551.328, 1817.658],
        [1561.863, 1922.598],
      ],
      [
        [1262.77, 2199.371],
        [1510.901, 2196.785],
        [1611.399, 2061.505],
        [1561.864, 1922.598],
        [1551.329, 1817.658],
        [1505.316, 1852.389],
        [1434.771, 1848.75],
        [1383.726, 1802.337],
        [1355.753, 1733.912],
        [1275.187, 1731.751],
      ],
      [
        [1026.684, 1740.332],
        [1038.034, 1718.641],
        [1275.186, 1731.752],
        [1262.768, 2199.371],
        [1143.184, 2202.37],
        [1001.385, 2142.705],
      ],
      [
        [1847.403, 2236.823],
        [1816.524, 2298.663],
        [1746.39, 2350.106],
        [1849.773, 2420.811],
        [1785.063, 2489.005],
        [1836.706, 2518.78],
        [2005.319, 2505.131],
        [2042.921, 2518.66],
        [2197.669, 2505.419],
        [2235.419, 2417.953],
        [2285.749, 2361.938],
        [2359.661, 2005.432],
        [2345.51, 1944.566],
        [2288.586, 1884.958],
        [2098.802, 1918.018],
        [1884.402, 2257.44],
      ],
      [
        [1785.062, 2489.005],
        [1760.184, 2475.861],
        [1589.265, 2329.391],
        [1551.766, 2264.286],
        [1510.571, 2242.604],
        [1510.902, 2196.785],
        [1825.198, 1762.725],
        [1890.279, 1813.19],
        [1903.765, 1799.606],
        [2098.802, 1918.017],
        [1884.402, 2257.439],
        [1847.404, 2236.823],
        [1818.304, 2308.003],
        [1746.391, 2350.106],
        [1849.774, 2420.811],
      ],
      [
        [1001.387, 2142.705],
        [1510.901, 2196.785],
        [1510.57, 2242.604],
        [1551.765, 2264.286],
        [1589.264, 2329.391],
        [1653.451, 2383.821],
        [1600.526, 2499.754],
        [1484.625, 2531.854],
        [1358.402, 2385.473],
        [1166.076, 2531.578],
        [1117.039, 2476.107],
        [1065.92, 2518.766],
        [880.893, 2292.228],
        [936.269, 2245.081],
      ],
      [
        [731.155, 2414.948],
        [880.894, 2292.229],
        [1065.921, 2518.767],
        [1117.04, 2476.108],
        [1166.077, 2531.579],
        [1358.403, 2385.474],
        [1484.626, 2531.855],
        [1490.755, 2592.89],
        [1444.047, 2640.087],
        [1308.018, 2698.606],
        [1207.111, 2791.833],
        [1004.303, 2734.095],
        [888.299, 2619.745],
        [852.81, 2645.037],
      ],
    ];

    var userLang = navigator.language;

    for (var i = 0; i < arr_min_max_rect.length; i++) {
      var min_max_rect = arr_min_max_rect[i];
      var check_min_max = this.inside(point, min_max_rect);

      if (check_min_max) {
        var coords = arr_coords[i];
        var check_coords = this.inside(point, coords);
        if (check_coords) {
          if (userLang === 'ko-KR')
            return arr_addr_kr[i][1] + ',  ' + arr_addr_kr[i][0];
          else return arr_addr_en[i][1] + ',  ' + arr_addr_en[i][0];
        } else {
          //console.log('out side...');
        }
      }
    }

    //못찾았을 경우
    if (userLang === 'ko-KR') return '배드랜드,  배드랜드';
    else return 'BADLANDS,  BADLANDS';
  };
}

export default GetCurrentAddressStr;
