import React, { useState, useEffect } from 'react';
import Loading from '../loading/Loading';
import LoadGeoJsonDataTask from '../../tasks/LoadGeoJsonDataTask';
import GetDataFromDB from '../../tasks/GetDataFromDB';
import NightcityMap from '../map/NightcityMap';

const Nightcity = () => {
  const [geoJsonData, setGeoJsonData] = useState([]);

  const load = () => {
    const loadGeoJsonDataTask = new LoadGeoJsonDataTask();
    const getDataFromDB = new GetDataFromDB();
    loadGeoJsonDataTask.load(setGeoJsonData);
    getDataFromDB.load(setGeoJsonData);
  };

  useEffect(load, []);

  return (
    <div>
      {geoJsonData.length === 0 ? (
        <Loading />
      ) : (
        <div>
          <NightcityMap geoJsonData={geoJsonData} />
        </div>
      )}
    </div>
  );
};

export default Nightcity;
