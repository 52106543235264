import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import Nightcity from './components/nightcity/Nightcity';

function App() {
  return (
    <Router>
      <Route path="/map/v2" component={Nightcity} />;
      <Route
        exact
        path="/"
        component={() => {
          global.window &&
            (global.window.location.href = 'http://onssoft.hosting.bizfree.kr');
          return null;
        }}
      />
    </Router>
  );
}

export default App;
