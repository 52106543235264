import data_kr from '../data/basemap_kr.json';
import data_en from '../data/basemap_en.json';

class LoadGeoJsonDataTask {
  load = (setState) => {
    var userLang = navigator.language;
    if (userLang === 'ko-KR') setState(data_kr.features);
    else setState(data_en.features);
  };
}

export default LoadGeoJsonDataTask;
