import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { CachedTileLayer } from '@yaga/leaflet-cached-tile-layer';
import { Alert, ModalDialog, Modal, Button } from 'react-bootstrap';
import GetCurrentAddressStr from '../utils/GetCurrentAddressStr';
import './Leaflet.css';

const center = { lat: 4376.0, lng: 2846.0 };
const containerCenter = { x: 4376.0, y: 2846.0 };
const pointCenter = { lat: 4376.0, lng: 2846.0 };

const NightcityMap = ({ geoJsonData }) => {
  const [map, setMap] = useState(null);
  const [districtName, setDistrictName] = useState('???');
  const [customPosition, setCustomPosition] = useState(center);
  const [customContainerPosition, setCustomContainerPosition] = useState(
    containerCenter,
  );
  const [pointPosition, setPointPosition] = useState(pointCenter);
  const markerRef = useRef(null);
  const pointMarkerRef = useRef(null);
  const address = new GetCurrentAddressStr();
  const minZoom: number = 3;
  const maxZoom: number = 6;

  // const width: number = 4376;
  // const height: number = 2846;
  //const width: number = 8752;
  //const height: number = 5694;
  const width: number = 8000;
  const height: number = 8552;
  const tileSize: number = 256;

  var gang_animals = L.layerGroup();
  var gang_voodo_boys = L.layerGroup();
  var gang_valentinos = L.layerGroup();
  var gang_scavengers = L.layerGroup();
  var gang_6th_street = L.layerGroup();
  var gang_tyger_claws = L.layerGroup();
  var gang_mealstrom = L.layerGroup();
  var gang_the_mox = L.layerGroup();
  var gang_wraiths = L.layerGroup();
  var gang_aldecaldos = L.layerGroup();

  var megaBuildingLayer = L.layerGroup();
  var landMarkLayer = L.layerGroup();
  var npcLayer = L.layerGroup();
  var districtLayer = L.layerGroup();
  var districtLineLayer = L.layerGroup();
  var barsLayer = L.layerGroup();
  var hiddenItemLayer = L.layerGroup();
  var weaponShopLayer = L.layerGroup();
  var meleeWeaponShopLayer = L.layerGroup();
  var netRunnerLayer = L.layerGroup();
  var ripperdocLayer = L.layerGroup();
  var clothingLayer = L.layerGroup();
  var vehicleLayer = L.layerGroup();
  var iconicWeaponLayer = L.layerGroup();

  let beforeMarker: L.Marker;

  const onReceivedPostMessage = (event) => {
    if (event.detail.action === 'move')
      goToPosition(event.detail.x, event.detail.y);
    else if (event.detail.action === 'moveAndShow')
      goToPositionAndShow(event.detail.x, event.detail.y);
    else if (event.detail.action === 'findAndMove')
      findAndMove(event.detail.layer, event.detail.svgID);
    else if (event.detail.action === 'hideAndShowLayer')
      hideAndShowLayer(event.detail.layerName, event.detail.visibility);
  };

  const mapZoomEnd = (event) => {};

  const mapMoveEnd = (event) => {
    var center = (map as L.Map).getCenter();
    var point = L.CRS.Simple.latLngToPoint(center, zoomLevel());

    setDistrictName(address.getCurrentAddressStr([point.x / 2, point.y / 2]));
  };

  const onMapClick = (event) => {
    setCustomPosition(event.latlng);
    setCustomContainerPosition(
      L.CRS.Simple.latLngToPoint(event.latlng, zoomLevel()),
    );

    whenCustomClicked(event);
    //(map as L.Map).flyTo(event.latlng);
    //markerRef.current.openPopup();
  };

  const findAndMove = (layerName, svgID) => {
    let findLayer: L.Marker;

    megaBuildingLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });
    
    npcLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    barsLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    hiddenItemLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    weaponShopLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    meleeWeaponShopLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    netRunnerLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    ripperdocLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    clothingLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    vehicleLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    iconicWeaponLayer.getLayers().forEach((layer) => {
      if ((layer as L.Marker).feature.properties.svgID === svgID) {
        findLayer = layer as L.Marker;
      }
    });

    let point = findLayer.feature.geometry.coordinates;

    findLayer.setIcon(
      makeIcon(
        findLayer.feature.properties.layer,
        findLayer.feature.properties.sublayer,
        findLayer.feature.properties.svgID,
        findLayer.feature.properties.w,
        findLayer.feature.properties.h,
        true,
      ),
    );

    beforeMarker = findLayer;

    goToPosition(point[0], point[1]);

    let position = L.CRS.Simple.latLngToPoint(
      beforeMarker.getLatLng(),
      zoomLevel(),
    );
    let dataJson: any = beforeMarker.feature.properties;

    dataJson.lat = beforeMarker.getLatLng().lat;
    dataJson.lng = beforeMarker.getLatLng().lng;
    dataJson.locX = position.x;
    dataJson.locY = position.y;
    dataJson.district = address.getCurrentAddressStr([point[0], point[1]]);

    window['showPointInfo'](JSON.stringify(dataJson));
  };

  const initMap = useCallback(
    (map: L.Map) => {
      map.setView(map.unproject([width / 2, height / 2], zoomLevel()), 3);

      var userLang = navigator.language;
      let imageUrl = '';
      if (userLang === 'ko-KR')
        imageUrl = 'http://www.couchbears.com/tiles_v20_kr/{z}/{x}/{y}.png';
      else imageUrl = 'http://www.couchbears.com/tiles_v20_en/{z}/{x}/{y}.png';

      const leafletCachedTileLayer = new CachedTileLayer(imageUrl, {
        attribution: `&copy; <a href="http://www.couchbears.com">CouchBears</a>`,
        updateInterval: 200,
        updateWhenIdle: true,
        databaseName: 'tile-cache-data', // optional
        databaseVersion: 1, // optional
        objectStoreName: 'OSM', // optional
        crawlDelay: 500, // optional
        maxAge: 1000 * 60 * 60 * 24 * 7, // optional
      });

      map.addLayer(leafletCachedTileLayer);
      map.on('zoomend', mapZoomEnd);
      map.on('moveend', mapMoveEnd);
      map.on('click', onMapClick);

      addGeoJsonLayer();

      map.addLayer(npcLayer);
      map.addLayer(barsLayer);
      map.addLayer(megaBuildingLayer);
      map.addLayer(weaponShopLayer);
      map.addLayer(meleeWeaponShopLayer);
      map.addLayer(netRunnerLayer);
      map.addLayer(ripperdocLayer);
      map.addLayer(clothingLayer);
      map.addLayer(vehicleLayer);
      map.addLayer(iconicWeaponLayer);
      // map.addLayer(hiddenItemLayer);
    },
    [map],
  );

  useEffect(() => {
    if (map !== null) {
      initMap(map);
      window.addEventListener('event', onReceivedPostMessage, false);
    }
    return () => {
      window.removeEventListener('event', onReceivedPostMessage, false);
    };
  }, [map, initMap]);

  function addGeoJsonLayer() {
    L.geoJSON(geoJsonData, {
      coordsToLatLng: coordsToLatLng,
      onEachFeature: onEachFeature,
      pointToLayer: pointToLayer,
      style: geoJsonStyle,
    });
  }

  const geoJsonStyle = (feature) => {
    if (feature.properties.style != null) return feature.properties.style;
    else return { color: '#aaddbb' };
  };

  const coordsToLatLng = (coords) => {
    var newCoords = [coords[0] * 2, coords[1] * 2];
    return map.unproject(newCoords, zoomLevel());
  };

  const pointToLayer = (feature, latlng) => {
    if (feature.properties.layer !== 'district_lines') {
      var marker = L.marker(latlng, {
        icon: makeIcon(
          feature.properties.layer,
          feature.properties.sublayer,
          feature.properties.svgID,
          feature.properties.w,
          feature.properties.h,
          false,
        ),
      });

      marker.on('click', whenClicked);

      return marker;
    } else {
      return L.marker(latlng);
    }
  };

  const whenCustomClicked = (e) => {
    if (beforeMarker != null) {
      beforeMarker.setIcon(
        makeIcon(
          beforeMarker.feature.properties.layer,
          beforeMarker.feature.properties.sublayer,
          beforeMarker.feature.properties.svgID,
          beforeMarker.feature.properties.w,
          beforeMarker.feature.properties.h,
          false,
        ),
      );
    }

    //console.log(JSON.stringify(e.sourceTarget.feature.properties));
    let point = L.CRS.Simple.latLngToPoint(e.latlng, zoomLevel());
    let latlng = L.CRS.Simple.pointToLatLng(point, zoomLevel());
    (map as L.Map).flyTo(latlng, zoomLevel());

    let position = L.CRS.Simple.latLngToPoint(e.latlng, zoomLevel());
    let dataJson: any = {};

    dataJson.lat = e.latlng.lat;
    dataJson.lng = e.latlng.lng;
    dataJson.locX = position.x;
    dataJson.locY = position.y;
    dataJson.district = address.getCurrentAddressStr([
      point.x / 2,
      point.y / 2,
    ]);

    window['showPointInfo'](JSON.stringify(dataJson));
  };

  const whenClicked = (e) => {
    if (beforeMarker != null) {
      // 이전에 선택한 마커가 있는 경우, 이전 마커의 아이콘을 원래대로
      beforeMarker.setIcon(
        makeIcon(
          beforeMarker.feature.properties.layer,
          beforeMarker.feature.properties.sublayer,
          beforeMarker.feature.properties.svgID,
          beforeMarker.feature.properties.w,
          beforeMarker.feature.properties.h,
          false,
        ),
      );
    }

    (e.target as L.Marker).setIcon(
      makeIcon(
        e.target.feature.properties.layer,
        e.target.feature.properties.sublayer,
        e.target.feature.properties.svgID,
        e.target.feature.properties.w,
        e.target.feature.properties.h,
        true,
      ),
    );

    beforeMarker = e.target as L.Marker;

    setCustomPosition(center);

    //console.log(JSON.stringify(e.sourceTarget.feature.properties));
    let point = L.CRS.Simple.latLngToPoint(e.latlng, zoomLevel());
    let latlng = L.CRS.Simple.pointToLatLng(point, zoomLevel());
    (map as L.Map).flyTo(latlng, zoomLevel());

    let position = L.CRS.Simple.latLngToPoint(e.latlng, zoomLevel());
    let dataJson: any = e.sourceTarget.feature.properties;

    dataJson.lat = e.latlng.lat;
    dataJson.lng = e.latlng.lng;
    dataJson.locX = position.x;
    dataJson.locY = position.y;
    dataJson.district = address.getCurrentAddressStr([
      point.x / 2,
      point.y / 2,
    ]);

    window['showPointInfo'](JSON.stringify(dataJson));
  };

  const onEachFeature = (feature, layer) => {
    if (feature.properties && feature.properties.sublayer == 'point') {
      layer.on({ click: whenClicked });
    }

    switch (feature.properties.layer) {
      case 'poi_gangs':
        splitGangs(feature.properties.svgID, layer);
        break;
      case 'poi_mega_buildings':
        megaBuildingLayer.addLayer(layer);
        break;
      case 'poi_landmark':
        landMarkLayer.addLayer(layer);
        break;
      case 'poi_npc':
        npcLayer.addLayer(layer);
        break;
      case 'poi_district':
        //districtLayer.addLayer(layer);
        break;
      case 'poi_bar':
        barsLayer.addLayer(layer);
        break;
      case 'district_lines':
        districtLineLayer.addLayer(layer);
        break;
      case 'poi_hidden_gem':
        hiddenItemLayer.addLayer(layer);
        break;
      case 'poi_weapon_shop':
        weaponShopLayer.addLayer(layer);
        break;
      case 'poi_melee_vendor':
        meleeWeaponShopLayer.addLayer(layer);
        break;
      case 'poi_netrunner':
        netRunnerLayer.addLayer(layer);
        break;
      case 'poi_ripperdoc':
        ripperdocLayer.addLayer(layer);
        break;
      case 'poi_clothing_vendor':
        clothingLayer.addLayer(layer);
        break;
      case 'poi_vehicle':
        vehicleLayer.addLayer(layer);
        break;
      case 'poi_iconic_weapon':
        iconicWeaponLayer.addLayer(layer);
        break;
    }
  };

  function splitGangs(id, layer) {
    switch (id) {
      case 'p_aldecaldos':
        gang_aldecaldos.addLayer(layer);
        break;
      case 'r_aldecaldos':
        gang_aldecaldos.addLayer(layer);
        break;

      case 'p_animals':
        gang_animals.addLayer(layer);
        break;
      case 'r_animals':
        gang_animals.addLayer(layer);
        break;

      case 'p_voodoo_boys':
        gang_voodo_boys.addLayer(layer);
        break;
      case 'r_voodoo_boys':
        gang_voodo_boys.addLayer(layer);
        break;

      case 'p_valentinos':
        gang_valentinos.addLayer(layer);
        break;
      case 'r_valentinos':
        gang_valentinos.addLayer(layer);
        break;

      case 'p_scavengers':
        gang_scavengers.addLayer(layer);
        break;
      case 'r_scavengers':
        gang_scavengers.addLayer(layer);
        break;

      case 'p_6th_street':
        gang_6th_street.addLayer(layer);
        break;
      case 'r_6th_street':
        gang_6th_street.addLayer(layer);
        break;

      case 'p_tyger_claws':
        gang_tyger_claws.addLayer(layer);
        break;
      case 'r_tyger_claws':
        gang_tyger_claws.addLayer(layer);
        break;

      case 'p_mealstrom':
        gang_mealstrom.addLayer(layer);
        break;
      case 'r_maelstrom':
        gang_mealstrom.addLayer(layer);
        break;

      case 'p_the_mox':
        gang_the_mox.addLayer(layer);
        break;
      case 'r_the_mox':
        gang_the_mox.addLayer(layer);
        break;

      case 'p_wraiths':
        gang_wraiths.addLayer(layer);
        break;
      case 'r_wraiths':
        gang_wraiths.addLayer(layer);
        break;
    }
  }

  function makeIcon(
    layer: string,
    sublayer: string,
    name: string,
    w: number,
    h: number,
    isClicked: boolean,
  ) {
    let iconUrl = '';
    if (isClicked) {
      if (layer == 'poi_mega_buildings') {
        iconUrl = '/images/' + name + '_clicked.png';
      } else {
        iconUrl = '/images/' + layer + '_clicked.png';
      }
    } else {
      if (layer == 'poi_mega_buildings') {
        iconUrl = '/images/' + name + '.png';
      } else {
        iconUrl = '/images/' + layer + '.png';
      }
    }

    if (layer == 'poi_gangs') iconUrl = '/images/' + name + '.png';

    let icon = L.icon({
      className: 'marker',
      iconUrl: iconUrl,
      iconRetinaUrl: iconUrl,
      iconSize: getIconSize(layer, sublayer, w, h),
      iconAnchor: [w / 8, h / 1.8 - 5],
      popupAnchor: [-0, -31],
      attribution: name,
    });

    return icon;
  }

  function getIconSize(
    layer: string,
    sublayer: string,
    w: number,
    h: number,
  ): [number, number] {
    if (layer == 'poi_gangs') return [w / 4.5, h / 2.5];
    else if (layer == 'poi_district') return [w / 6, h / 6];
    else return [w / 4, h / 1.8];
  }

  /**
   * 현재 지도의 Zoom Level 을 구하는 함수
   */
  function zoomLevel() {
    return Math.ceil(
      Math.log(Math.max(width, height) / tileSize) / Math.log(2),
    );
  }

  /**
   * 안드로이드에서 검색 결과 위치로 이동하는 함수
   * @param x x 좌표
   * @param y y 좌표
   */
  function goToPosition(x: number, y: number) {
    (map as L.Map).flyTo(map.unproject([x * 2, y * 2], zoomLevel()), 6);
  }

  function goToPositionAndShow(x: number, y: number) {
    (map as L.Map).flyTo(map.unproject([x, y], zoomLevel()), 6);
    setPointPosition(
      L.CRS.Simple.pointToLatLng(new L.Point(x, y), zoomLevel()),
    );

    let dataJson: any = {};

    dataJson.layer = '';
    dataJson.sublayer = '';
    dataJson.svgID = '';
    dataJson.name = '';
    dataJson.w = 0;
    dataJson.h = 0;
    dataJson.lat = L.CRS.Simple.pointToLatLng(
      new L.Point(x, y),
      zoomLevel(),
    ).lat;
    dataJson.lng = L.CRS.Simple.pointToLatLng(
      new L.Point(x, y),
      zoomLevel(),
    ).lng;
    dataJson.locX = x;
    dataJson.locY = y;
    dataJson.district = address.getCurrentAddressStr([x / 2, y / 2]);

    window['showPointInfo'](JSON.stringify(dataJson));
  }

  function hideAndShowLayer(layerName: string, visibility: boolean) {
    switch (layerName) {
      case 'gang6thStreet':
        if (visibility) map.addLayer(gang_6th_street);
        else map.removeLayer(gang_6th_street);
        break;
      case 'gangAldecaldos':
        if (visibility) map.addLayer(gang_aldecaldos);
        else map.removeLayer(gang_aldecaldos);
        break;
      case 'gangAnimals':
        if (visibility) map.addLayer(gang_animals);
        else map.removeLayer(gang_animals);
        break;
      case 'gangMaelstrom':
        if (visibility) map.addLayer(gang_mealstrom);
        else map.removeLayer(gang_mealstrom);
        break;
      case 'gangMoxes':
        if (visibility) map.addLayer(gang_the_mox);
        else map.removeLayer(gang_the_mox);
        break;
      case 'gangTygerClaws':
        if (visibility) map.addLayer(gang_tyger_claws);
        else map.removeLayer(gang_tyger_claws);
        break;
      case 'gangValentinos':
        if (visibility) map.addLayer(gang_valentinos);
        else map.removeLayer(gang_valentinos);
        break;
      case 'gangVoodooBoys':
        if (visibility) map.addLayer(gang_voodo_boys);
        else map.removeLayer(gang_voodo_boys);
        break;
      case 'gangWraiths':
        if (visibility) map.addLayer(gang_wraiths);
        else map.removeLayer(gang_wraiths);
        break;
      case 'megaBuildings':
        if (visibility) map.addLayer(megaBuildingLayer);
        else map.removeLayer(megaBuildingLayer);
        break;
      case 'landmark':
        if (visibility) map.addLayer(landMarkLayer);
        else map.removeLayer(landMarkLayer);
        break;
      case 'districts':
        if (visibility) {
          map.addLayer(districtLineLayer);
          map.addLayer(districtLayer);
        } else {
          map.removeLayer(districtLineLayer);
          map.removeLayer(districtLayer);
        }
        break;
      case 'barsAndClubs':
        if (visibility) map.addLayer(barsLayer);
        else map.removeLayer(barsLayer);
        break;
      case 'npc':
        if (visibility) map.addLayer(npcLayer);
        else map.removeLayer(npcLayer);
        break;
      case 'clothing':
        if (visibility) map.addLayer(clothingLayer);
        else map.removeLayer(clothingLayer);
        break;
      case 'meleeVendor':
        if (visibility) map.addLayer(meleeWeaponShopLayer);
        else map.removeLayer(meleeWeaponShopLayer);
        break;
      case 'weaponShop':
        if (visibility) map.addLayer(weaponShopLayer);
        else map.removeLayer(weaponShopLayer);
        break;
      case 'netrunner':
        if (visibility) map.addLayer(netRunnerLayer);
        else map.removeLayer(netRunnerLayer);
        break;
      case 'ripperdoc':
        if (visibility) map.addLayer(ripperdocLayer);
        else map.removeLayer(ripperdocLayer);
        break;
      case 'vehicle':
        if (visibility) map.addLayer(vehicleLayer);
        else map.removeLayer(vehicleLayer);
        break;
      case 'iconicWeapon':
        if (visibility) map.addLayer(iconicWeaponLayer);
        else map.removeLayer(iconicWeaponLayer);
        break;
    }
  }

  return (
    <MapContainer
      minZoom={minZoom}
      maxZoom={maxZoom}
      zoom={minZoom}
      crs={L.CRS.Simple}
      zoomControl={false}
      whenCreated={setMap}
    >
      {/* <TileLayer
        attribution='<a href="http://www.couchbears.com">CouchBears</a>'
        url="http://192.168.0.103:3000/tiles_v20_kr/{z}/{x}/{y}.png"
        detectRetina={true}
        noWrap={true}
        updateWhenIdle={true}
      /> */}
      <div
        style={{ position: 'absolute', top: 0, zIndex: 1000, width: '100%' }}
      >
        <Alert
          variant="light"
          style={{ margin: 8, fontSize: 14, color: '#000000' }}
        >
          {districtName}
        </Alert>
      </div>

      <Marker
        ref={pointMarkerRef}
        position={pointPosition}
        icon={L.icon({
          className: 'marker',
          iconUrl: '/images/ic_point.png',
          iconRetinaUrl: '/images/ic_point.png',
          iconSize: [20, 30],
          iconAnchor: [10, 30],
          popupAnchor: [0, -30],
        })}
      />
      <Marker
        ref={markerRef}
        position={customPosition}
        icon={L.icon({
          className: 'marker',
          iconUrl: '/images/ic_point.png',
          iconRetinaUrl: '/images/ic_point.png',
          iconSize: [20, 30],
          iconAnchor: [10, 30],
          popupAnchor: [0, -30],
        })}
      />
    </MapContainer>
  );
};

export default NightcityMap;
