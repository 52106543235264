import { features } from '../data/basemap_en.json';
import axios from 'axios';

class GetDataFromDB {
  load = (setState) => {
    //var result = axios.get('/gang/getGangList');
    //console.log(result);
  };
}

export default GetDataFromDB;
